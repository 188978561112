<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Branch Cash Collections</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>

      <div class="flex">
        <div>
          <input
            class="p-2 border w-80 rounded"
            v-model="filter"
            @keyup="search()"
            type="search"
            placeholder="Search"
          />
        </div>
        <button @click="rqFilter()" class="mx-2">
          <span>
            <i
              :class="{ 'text-blue-600': onFilter }"
              class="bx bx-filter-alt text-2xl"
            ></i>
          </span>
        </button>
      </div>

      <router-link
        to="/newcollection"
        class="
          border
          px-6
          py-2
          rounded-lg
          text-sm
          flex
          justify-center
          items-center
          hover:bg-gray-100
        "
      >
        New Collection
        <i class="bx bxs-plus-circle pl-1"></i>
      </router-link>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <div
              v-if="onFilter"
              class="flex grid-flow-col gap-4 bg-gray-50 p-3"
            >
              <!-- <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch"
                  >Filter by branch
                </label>
                <select
                  class="p-2 border rounded"
                  name="branch"
                  @change="loadData(1, filter)"
                  v-model="filterBranch"
                >
                  <option value="" disabled selected>Filter by branch</option>
                  <option value="">All</option>
                  <option
                    v-for="(branch, i) in branches"
                    :key="i"
                    :value="branch.id"
                  >
                    {{ branch.name }}
                  </option>
                </select>
              </div> -->

              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch"
                  >Filter by status
                </label>
                <select
                  class="p-2 border rounded"
                  name="isReconcile"
                  @change="loadData(1, filter)"
                  v-model="isReconcile"
                >
                  <option value="" disabled selected>Filter by Status</option>
                  <option value="">All</option>

                  <option :value="'PENDING'">Pending</option>
                  <option :value="'RECONCILED'">collected</option>
                </select>
              </div>

              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch"
                  >Filter by date
                </label>
                <input
                  class="p-2 border rounded text-xs"
                  type="date"
                  name="date"
                  @change="loadData(1, filter)"
                  v-model="filterDate"
                />
              </div>
            </div>
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Branch / Date
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Total Sales
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Total Deposited
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Bank
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Created By
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Created At
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="cashcollections"
                class="bg-white divide-y divide-gray-200"
              >
                <tr v-for="(collection, i) in cashcollections.data" :key="i">
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ collection.branch }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ collection.date }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    GMD <b>{{ collection.total_sales }}</b>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    GMD <b>{{ collection.total_deposited }}</b>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ collection.bank }}
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ collection.created_by }}
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ collection.created_at }}
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <span
                      v-if="collection.isReconcile == 'RECONCILED'"
                      class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-green-100
                        text-green-800
                      "
                    >
                      {{ collection.isReconcile }}
                    </span>

                    <span
                      v-if="collection.isReconcile == 'PENDING'"
                      class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-yellow-100
                        text-yellow-800
                      "
                    >
                      {{ collection.isReconcile }}
                    </span>
                  </td>
                  <!-- 
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
                  >
                    <a href="#" class="text-indigo-600 hover:text-indigo-900"
                      >View</a
                    >
                  </td> -->
                </tr>

                <!-- More people... -->
              </tbody>
            </table>

            <div class="border-t" v-if="cashcollections">
              <pagination
                :data="cashcollections"
                :midSize="2"
                @clicked="loadData"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "../../../components/Pagination";

import { mapGetters } from "vuex";
import checkPermissionExist from "../../../mixins/checkPermissionExist";

// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",
  components: { Pagination },

  layout: "default",

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),
  data: () => ({
    cashcollections: null,

    filter: "",
    onFilter: false,

    isReconcile: "",
    filterBranch: "",
    filterDate: "",
    branches: [],
  }),

  created() {
    // var _this = this;
    // _this.loadData(1);
    if (
      !this.checkPermissionExist(this.user.data, "cashcollection-branch-read")
    ) {
      this.$router.go(-1);
      return false;
    }
  },

  mounted() {
    this.loadData(1, this.filter);
  },

  methods: {
    async loadData(pageNo, filter) {
      // Submit the form
      if (filter) {
        // filter = filter;
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      try {
        const { data } = await axios.get(
          `/cashcollections?page=${pageNo}&filter=${this.filterDate}&branch_id=${this.user.data.staff.branch_id}&isReconcile=${this.isReconcile}`
        );

        console.log(data);

        this.cashcollections = data;
        this.searching = false;

        // console.log("The current page is: ", this.agents.meta.current_page);

        return true;
      } catch (e) {
        console.log("Error loading collection", e);
        this.searching = false;
        return false;
      }
    },

    search() {
      var vm = this;
      this.searching = true;
      vm.loadData(1, vm.filter);
    },

    rqFilter() {
      this.onFilter = !this.onFilter;

      if (this.onFilter) {
        this.loadBranches();
        // this.loadStaffs();
      }
    },

    async loadBranches() {
      // Submit the form

      try {
        const { data } = await axios.get(`/get/branches`);

        this.branches = data.data;
        // this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },
  },
};
</script>
